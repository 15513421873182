import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import UserImpersonationContainer from './containers/UserImpersonationContainer';
import { colors } from './theme';

function App() {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const theme = extendTheme({
    colors: {
      colors,
    },
  });

  return (
    <>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          {!isAuthenticated && !isLoading ? (
            loginWithRedirect()
          ) : (
            <UserImpersonationContainer />
          )}
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
}

export default App;
