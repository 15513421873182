export const gradients = {
  primaryBackground: [
    'linear(to-tr, #edd698, #a381d6)',
    'linear(to-t, #7adef5, #84e8c3)',
    'linear(to-b, #01111c, #2b5663)',
  ],
  blueMartini: [
    'linear-gradient(270.4deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
    'linear-gradient(90deg, #1BB9CF -3.87%, #00A0B6 52%, #0E86E6 102.86%);',
  ],
};

export const colors = {
  primary: '#009FB5',
  secondary: '#0A92A5',
  white: '#F5F5F5',
  black: '#111827',
  error: '#EF4444',
  lightestGrey: '#E8E8E8',
  lightTealGrey: '#BFC9CB',
  darkTealGrey: '#363D41',
  teal: '#006873',
  medTeal: '#009FB5',
  darkestTeal: '#0B252B',
  darkestTealGrey: '#21292D',
  charcoalGrey: '#84878B',
};
