import { React, useState } from 'react';
import {
  Flex,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/react';
import { ImpersonateService } from '../services/ImpersonateService';
import useEmailValidation from '../hooks/useEmailValidation';
import { useAuth0 } from '@auth0/auth0-react';
import { PrimaryButton } from '../_library/PrimaryButton';

const ImpersonationForm = ({ goToSummary, handleAlert }) => {
  const [emailToImpersonate, setEmailToImpersonate] = useState('');
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const validateEmail = useEmailValidation(emailToImpersonate);

  const sendToImpersonateUserEndpoint = e => {
    e.preventDefault();
    ImpersonateService(
      getAccessTokenSilently,
      emailToImpersonate,
      handleAlert,
      handleInvalidEmail,
      handleIsLoading,
      goToSummary
    );
  };

  const handleEmailChange = e => {
    setEmailToImpersonate(e.target.value);
  };

  const handleInvalidEmail = () => {
    setFormError(true);
  };

  const handleIsLoading = bool => {
    setIsLoading(bool);
  };

  return (
    <>
      <form onSubmit={e => sendToImpersonateUserEndpoint(e)}>
        <FormControl isRequired mt="3" isInvalid={formError}>
          <FormLabel>Enter email address of user to impersonate:</FormLabel>
          <Input
            placeholder="user@email.com"
            focusBorderColor="brand.100"
            onClick={() => setFormError(false)}
            onChange={e => handleEmailChange(e)}
          />
          {formError ? (
            <FormHelperText color="black">
              Please check for any errors in the email address entered.
            </FormHelperText>
          ) : null}
        </FormControl>
        <Flex mt={3}>
          <PrimaryButton
            type="submit"
            text="Submit"
            isDisabled={!validateEmail}
            isLoading={isLoading}
          />
        </Flex>
      </form>
    </>
  );
};

export default ImpersonationForm;
