import { React } from "react";
import { Alert, AlertIcon, AlertTitle, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';


const UserAlert = ({toggleAlert, alertStatus, alertMessage, handleToggleOffAlert}) => { 
  const prefersReducedMotion = usePrefersReducedMotion()

  const fadeIn = keyframes`
      from {
        opacity: 0;
      }
      to {
        opacity: 0.5;
      }
    `

  const animation = prefersReducedMotion
  ? undefined
  : `${ fadeIn } 0.1s linear`

  const alertTheme = {
      w: '23vw',
      h: 'auto',
      borderRadius: '5px',
      color: '#FFFFFF',
      fontSize: 'lg',
      bgGradient: alertStatus === 'success' ? ['linear(to-r, #4AA543, #56BB4E, #56BB4E)'] : ['linear(to-r, #BA2121, #C52C2C, #C52C2C, #BA2121)'],
      boxShadow: 'lg',
      textShadow: alertStatus === 'success' ? '1px 1px #3E8D37' : '1px 1px #9F3737',
      zIndex:'99',
      ml:'70vw',
      position:'fixed',
      cursor: 'pointer'
  }

  const displayAlert = () => {
    if(toggleAlert === true){
    return  (
              <Alert 
                status={ alertStatus } 
                onClick={ handleToggleOffAlert }
                w={ alertTheme.w }
                h={ alertTheme.h }
                borderRadius={ alertTheme.borderRadius }
                bgGradient={ alertTheme.bgGradient }
                boxShadow={ alertTheme.boxShadow }
                zIndex={ alertTheme.zIndex }
                ml={ alertTheme.ml }
                position={ alertTheme.position }
                cursor={ alertTheme.cursor }
                animation={ animation }
              >
                <AlertIcon color={ alertTheme.color }/>
                <AlertTitle 
                  fontSize={ alertTheme.fontSize } 
                  color={ alertTheme.color } 
                  textShadow={ alertTheme.textShadow }
                >
                  { alertMessage }
                </AlertTitle>
              </Alert> 
    );
    } else {
      return null;
    }
  }

  return (
    <>
      { displayAlert() }
    </>
  );
}

export default UserAlert;