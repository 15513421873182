import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { settings } from './settings';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <Auth0Provider
      domain={settings.auth0Domain}
      clientId={settings.auth0ClientId}
      redirectUri={window.location.origin}
      audience={settings.auth0DomainApiV2}
    >
    <ColorModeScript />
    <App />
  </Auth0Provider>
  </StrictMode >
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
