import { Button, Icon, Flex } from '@chakra-ui/react';

export const SecondaryButton = ({
  text,
  onClick,
  icon,
  sm = false,
  isDisabled,
  isLoading,
  loadingText,
  dataTestId,
}) => {
  return (
    <Button
      color={'colors.darkestTealGrey'}
      variant={'outline'}
      border={'1px solid'}
      borderColor={'colors.darkestTealGrey'}
      borderRadius={'999px'}
      paddingLeft={sm ? '5' : '6'}
      paddingRight={sm ? (icon ? '4' : '5') : icon ? '5' : '6'}
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingText={loadingText}
      _disabled={{ bg: 'charcoal.300' }}
      _focus={{ bgColor: 'colors.lightestGrey' }}
      _hover={{ bgColor: 'colors.lightestGrey' }}
      data-testid={dataTestId}
    >
      <Flex gap={3} align={'center'}>
        {text}
        {icon && <Icon as={icon} />}
      </Flex>
    </Button>
  );
};
