import { React, useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Heading,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  Text,
  Flex,
  Select,
  HStack,
} from '@chakra-ui/react';
import { MdOutlinePeopleOutline } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';
import { useAuth0 } from '@auth0/auth0-react';
import { ImpersonateResetService } from '../services/ImpersonateResetService';
import useCheckUserRole from '../hooks/useCheckUserRole';
import { PrimaryButton } from '../_library/PrimaryButton';
import { SecondaryButton } from '../_library/SecondaryButton';

const ImpersonationButtonGroup = ({
  goToImpersonationForm,
  handleAlert,
  isImpersonation,
  userInfo,
}) => {
  const [controlImpersonationButton, setControlImpersonationButton] =
    useState();
  const [controlResetButton, setControlResetButton] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // const toggleImpersonateButton = useCheckUserRole(userInfo);
  const toggleResetButton = isImpersonation === true;

  const handleImpersonationButton = bool => {
    setControlImpersonationButton(bool);
  };

  const handleResetButton = bool => {
    setControlResetButton(bool);
    setIsLoading(false);
  };

  const resetUser = () => {
    ImpersonateResetService(
      getAccessTokenSilently,
      handleAlert,
      handleImpersonationButton,
      handleResetButton
    ).then(setIsOpen);
  };

  const handleSelectDesignType = e => {
    if (e.target.value === 'resetUser') {
      resetUser();
    } else {
      goToImpersonationForm();
    }
  };

  // useEffect(() => {
  //     handleImpersonationButton(toggleImpersonateButton)
  //     handleResetButton(toggleResetButton);
  //  },[]);

  return (
    <Flex direction={'column'} gap={4}>
      <Heading size="lg" my="4">
        What do you want to do?
      </Heading>
      <HStack spacing="4" direction={'column'}>
        <PrimaryButton
          text="Impersonate a user"
          icon={MdOutlinePeopleOutline}
          // isDisabled={ controlResetButton }
          onClick={goToImpersonationForm}
          sm={true}
        />
        <Popover isOpen={isOpen}>
          <PopoverTrigger>
            <SecondaryButton
              text="Reset my user"
              icon={RxReset}
              // isDisabled={ controlResetButton }
              onClick={() => setIsOpen(true)}
            />
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverHeader fontWeight="500">
                Are you sure you want to reset your user? Ensure you are using
                your impersonation account before proceeding.
              </PopoverHeader>
              <PopoverCloseButton />
              <PopoverBody>
                <PrimaryButton
                  text="Confirm Reset"
                  // isDisabled={ controlResetButton }
                  onClick={() => {
                    resetUser();
                    setIsLoading(true);
                  }}
                  isLoading={isLoading}
                  sm={true}
                />
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
    </Flex>
  );
};

export default ImpersonationButtonGroup;
