//* API
const API_HOST_V1 =
  process.env.REACT_APP_API_V1_HOST_DEV || window.ENV_CONFIG.hosts.api_v1;
const API_HOST_V2 =
  process.env.REACT_APP_API_V2_HOST_DEV || window.ENV_CONFIG.hosts.api_v2;
const ORDERS_SERVICE =
  process.env.REACT_APP_API_ORDERS_SERVICE ||
  window.ENV_CONFIG.hosts.orders_service;

//* UserRoleService
const API_HOST_V1_PROFILE = `${ORDERS_SERVICE}/Profile`;

//* Auth0 Config
const mrmServicesAudience =
  process.env.REACT_APP_AUTH0_MRM_SERVICES_DEV ||
  window.ENV_CONFIG.auth0.mrm_services_audience;
const clientId =
  process.env.REACT_APP_AUTH0_CLIENT_ID_DEV ||
  window.ENV_CONFIG.auth0.client_id;
const domain =
  process.env.REACT_APP_AUTH0_DOMAIN_DEV || window.ENV_CONFIG.auth0.domain;

//* External links
const toolkitPortal =
  process.env.REACT_APP_LINK_TOOLKIT_PORTAL ||
  window.ENV_CONFIG.links.toolkit_portal;

export const settings = {
  apiHostV1: API_HOST_V1,
  apiHostV2: API_HOST_V2,
  brandsEndPoint: `${ORDERS_SERVICE}/brands`,
  marketsEndPoint: `${ORDERS_SERVICE}/markets`,
  menuOptionsEndPoint: `${ORDERS_SERVICE}/menuoptions`,
  apiV1Profile: API_HOST_V1_PROFILE,
  mrmServices: mrmServicesAudience,
  auth0Domain: domain,
  auth0DomainApiV2: `${domain}/api/v2/`,
  auth0ClientId: clientId,
  toolkitPortal: toolkitPortal,
};