import React from 'react';
import { Heading, Text, Flex, Box } from '@chakra-ui/react';
import { PrimaryButton } from '../_library/PrimaryButton';

const Summary = () => {
  const restartProcess = () => {
    window.location.reload(true);
  };

  return (
    <Flex direction="column">
      <Heading size="lg">Success!</Heading>
      <Text fontSize="lg" fontWeight="500" mt="5">
        You are now impersonating the user account.
      </Text>
      <Box mt={6}>
        <PrimaryButton text="Restart" onClick={restartProcess} />
      </Box>
    </Flex>
  );
};

export default Summary;
