import { settings } from "../settings";

const { auth0DomainApiV2 } = settings;

export async function ImpersonateResetService(getAccessTokenSilently, handleAlert, handleImpersonationButton, handleResetButton) {
    try {
        const token = await getAccessTokenSilently({
            audience: auth0DomainApiV2,
            scope: 'write:app_metadata',
        });

    const response = await fetch('https://user-impersonation-mrm.azurewebsites.net/api/ResetMe?code=axAD7eyiNoVgc6WzSUCdlfpGUwA86eZpYw6davINCg4kAzFufnSF2A==', {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-CLIENT-TYPE': 'Bespoke',
            'X-APP-LOCALE': 'en-GB',
        }
        });
        
        if(response.status === 200){
            handleAlert('success', `Success! Your user role has been reset.`);
            handleImpersonationButton(false);
            handleResetButton(true);
          } else {
            handleAlert('error', `Error! Failed to reset your user role.`);
          }

    } catch (e) {
        console.error(e);
    }
    }
