import { React, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Flex, Spinner } from '@chakra-ui/react';
import ImpersonationButtonGroup from '../components/ImpersonationButtonGroup';
import ImpersonationForm from '../components/ImpersonationForm';
import UserAlert from '../components/UserAlert';
import Summary from '../components/Summary';
import Header from '../components//header/Header';

const UserImpersonationContainer = () => {
  // Page rendering state
  const [showButtons, setShowButtons] = useState(true);
  const [showImpersonateForm, setShowImpersonateForm] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  // User role state
  const [isLoading, setIsLoading] = useState(false); // when uncommenting profile call, this should be returned to true
  // const [userInfo, setUserInfo] = useState({});
  // User alert state
  const [toggleAlert, setToggleAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState();
  const [alertMessage, setAlertMessage] = useState();

  const { getAccessTokenSilently } = useAuth0();

  // const { isImpersonation } = userInfo;

  const goToImpersonationForm = () => {
    setShowButtons(!showButtons);
    setShowImpersonateForm(!showImpersonateForm);
  };

  const goToSummary = () => {
    setShowImpersonateForm(false);
    setShowSummary(true);
  };

  const backToHomePage = () => {
    setShowButtons(true);
    setShowImpersonateForm(false);
    setShowSummary(false);
  };

  //   const handleIsLoading = () => {
  //     setIsLoading(false);
  //   };

  // const handleUserInfo = (user) => {
  //     setUserInfo(user)
  // }

  // useEffect(() => {
  //     UserRoleService(getAccessTokenSilently, handleIsLoading, handleUserInfo)
  //     },[getAccessTokenSilently]);

  const handleToggleOnAlert = () => {
    setToggleAlert(true);
  };

  const handleToggleOffAlert = () => {
    setToggleAlert(false);
  };

  const handleAlert = (alertStatus, alertMessage) => {
    handleToggleOffAlert();
    setAlertStatus(alertStatus);
    setAlertMessage(alertMessage);
    handleToggleOnAlert();
    setTimeout(handleToggleOffAlert, 7000);
  };

  const directUser = () => {
    if (isLoading === true) {
      return (
        <>
          <Spinner size="lg" />
        </>
      );
      //* Relies on BE refactor before being implemented
      // } else if(isUserImpersonation === false){
      //     return (
      //         <>
      //             <UnauthorisedLogin/>
      //         </>
      //     )
    } else {
      return (
        <>
          {showButtons ? (
            <ImpersonationButtonGroup
              goToImpersonationForm={goToImpersonationForm}
              handleAlert={handleAlert}
              // isImpersonation={ isImpersonation }
              // userInfo={ userInfo }
            />
          ) : null}
          {showImpersonateForm ? (
            <ImpersonationForm
              // userInfo={ userInfo }
              goToSummary={goToSummary}
              handleAlert={handleAlert}
              backToHomePage={backToHomePage}
            />
          ) : null}
          {showSummary && isLoading === false ? <Summary /> : null}
        </>
      );
    }
  };

  return (
    <>
      <Header />
      <UserAlert
        toggleAlert={toggleAlert}
        alertMessage={alertMessage}
        alertStatus={alertStatus}
        handleToggleOffAlert={handleToggleOffAlert}
      />
      <Flex
        p={20}
        alignItems="flex-start"
        color="brand.500"
        direction={'column'}
      >
        {directUser()}
      </Flex>
    </>
  );
};

export default UserImpersonationContainer;
