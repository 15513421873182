import { settings } from "../settings";

const { auth0DomainApiV2 } = settings;

export async function ImpersonateService(getAccessTokenSilently, emailToImpersonate, handleAlert, handleInvalidEmail, handleIsLoading, goToSummary) {

    handleIsLoading(true);

    try {
        const token = await getAccessTokenSilently({
            audience: auth0DomainApiV2,
            scope: 'write:app_metadata',
        });
        
        const response = await fetch(`https://user-impersonation-mrm.azurewebsites.net/api/ImpersonateUser?code=KaxogVzTKWDVjpdWdp2ktSqB4ZRKdRAhQsQvaa_Ix1_4AzFuEoqPUg==&user=${emailToImpersonate}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CLIENT-TYPE': 'Bespoke',
                'X-APP-LOCALE': 'en-GB',

            }
        })

        if(response.status === 200){
            handleAlert('success', `Success! User account impersonated.`)
          } else {
            handleAlert('error', `Error! Failed to impersonate user.`);
            handleInvalidEmail();
          }
        
        if(response.status === 200){
            goToSummary();
        }

    } catch (e) {
        console.error(e);
        handleIsLoading(false);
    }
    
    handleIsLoading(false);
}
